/* eslint-disable no-nested-ternary */
import { useLocation, matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

// export function useActiveLink(path, deep = true) {
//   const { pathname } = useLocation();

//   const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;

//   const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

//   return deep ? deepActive : normalActive;
// }

// ! custom -->>
export function useActiveLink(path, commonActive, deep = true) {
  const { pathname } = useLocation();

  const normalActive = path
    ? commonActive
      ? pathname.split('/').includes(commonActive)
      : !!matchPath({ path, end: true }, pathname)
    : false;

  const deepActive = path
    ? commonActive
      ? pathname.split('/').includes(commonActive)
      : !!matchPath({ path, end: false }, pathname)
    : false;

  return deep ? deepActive : normalActive;
}
