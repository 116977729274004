import Button from '@mui/material/Button';
import { useCallback } from 'react';
// import { Typography } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import MenuItem from '@mui/material/MenuItem';
// import { m } from 'framer-motion';
// import { varHover } from 'src/components/animate';
// import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const locales = useLocales();

  // const popover = usePopover();

  // const handleChangeLang = useCallback(
  //   (newLang) => {
  //     locales.onChangeLang(newLang);
  //     popover.onClose();
  //   },
  //   [locales, popover]
  // );

  const handleButtonChangeLang = useCallback(() => {
    const lang = locales.currentLang.value === 'guj' ? 'en' : 'guj';
    locales.onChangeLang(lang);
  }, [locales]);

  return (
    <>
      <Button
        variant={locales.currentLang.value === 'guj' ? 'contained' : 'outlined'}
        onClick={handleButtonChangeLang}
        startIcon={<Iconify icon="uil:language" />}
      >
        Guj. / Eng.
      </Button>

      {/* <Typography variant="p" onClick={popover.onOpen} sx={{ fontWeight: 700, cursor: 'pointer' }}>
        Language :
      </Typography>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify icon={locales.currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {locales.allLangs.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === locales.currentLang.value}
            onClick={() => handleChangeLang(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </CustomPopover> */}
    </>
  );
}
