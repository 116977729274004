import React from 'react';
import { Helmet } from 'react-helmet-async';

const ReligiousMain = () => (
  <>
    <Helmet>
      <title> HSSF : RELIGIOUS</title>
    </Helmet>

    <h1>Religious Main</h1>
  </>
);

export default ReligiousMain;
