import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
// auth
// import { useAuthContext } from 'src/auth/hooks';
// routes
// import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import { useLocation } from 'react-router';
import Logo from 'src/components/logo';
// import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

// const METHODS = [
//   {
//     id: 'jwt',
//     label: 'Jwt',
//     path: paths.auth.jwt.login,
//     icon: '/assets/icons/auth/ic_jwt.svg',
//   },
//   {
//     id: 'firebase',
//     label: 'Firebase',
//     path: paths.auth.firebase.login,
//     icon: '/assets/icons/auth/ic_firebase.svg',
//   },
//   {
//     id: 'amplify',
//     label: 'Amplify',
//     path: paths.auth.amplify.login,
//     icon: '/assets/icons/auth/ic_amplify.svg',
//   },
//   {
//     id: 'auth0',
//     label: 'Auth0',
//     path: paths.auth.auth0.login,
//     icon: '/assets/icons/auth/ic_auth0.svg',
//   },
// ];

export default function AuthClassicLayout({ children, image, title }) {
  // const { method } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  // const { t } = useLocales();

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');
  const mdDown = useResponsive('down', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        boxShadow:
          paths.auth.jwt.login === pathname &&
          mdUp &&
          'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
        width: 1,
        mx: 'auto',
        maxWidth: paths.auth.jwt.login === pathname ? 480 : 1300,
        // maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: {
          xs: [paths.auth.jwt.register, paths.auth.jwt.login].includes(pathname) ? 15 : 10,
          md: 20,
        },
        pb: { xs: 15, md: paths.auth.jwt.login === pathname ? 0 : 5 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Typography
        variant="h4"
        sx={{ maxWidth: 700, textAlign: 'center', textTransform: 'uppercase' }}
      >
        {/* HSSF - {t('hssf.hssf_full_name')} */}
        hindu spiritual and service foundation
        <br />
        હિન્દુ આધ્યાત્મિક અને સેવા સંસ્થાન
      </Typography>
      <Grid container spacing={5}>
        <Grid xs={12} md={6}>
          <Box
            component="img"
            alt="auth"
            src={image || '/assets/illustrations/hssf-logo.png'}
            sx={{
              width: 300,
              maxHeight: 300,
              filter: 'drop-shadow(10px 6px 8px rgba(14, 30, 37, 0.32))',
            }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Box
            component="img"
            alt="auth"
            src="/assets/illustrations/imct-logo.png"
            sx={{
              width: 300,
              maxHeight: 300,
              filter: 'drop-shadow(10px 6px 8px rgba(14, 30, 37, 0.32))',
            }}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        sx={{ maxWidth: 700, textAlign: 'center', textTransform: 'uppercase' }}
      >
        initiative for moral and cultural training foundation
        <br /> સાંસ્કૃતિક તથા નૈતિક પ્રશિક્ષણ પ્રકલ્પ
      </Typography>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {[paths.auth.jwt.register, paths.hssfPaths.registration.root].includes(pathname)
        ? renderLogo
        : mdDown && renderLogo}
      {paths.auth.jwt.login === pathname && mdUp && renderSection}
      {/* {mdUp && renderSection} */}
      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
