/* eslint-disable no-else-return */
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// routes
import { RouterLink } from 'src/routes/components';
import { useNavigate } from 'react-router';
// import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

export default function BreadcrumbsLink({ link, activeLast, disabled }) {
  const { name, href, icon } = link;
  const navigate = useNavigate();
  // const router = useRouter();

  const styles = {
    typography: 'body2',
    alignItems: 'center',
    color: 'text.primary',
    display: 'inline-flex',
    cursor: 'pointer',
    ...(disabled &&
      !activeLast && {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'text.disabled',
      }),
  };

  const renderContent = (
    <>
      {icon && (
        <Box
          component="span"
          sx={{
            mr: 1,
            display: 'inherit',
            '& svg': { width: 20, height: 20 },
          }}
        >
          {icon}
        </Box>
      )}

      {name}
    </>
  );

  // if (href) {
  //   return (
  //     <Link component={RouterLink} href={href} sx={styles}>
  //       {renderContent}
  //     </Link>
  //   );
  // }

  // =====================================================================================  1st
  // if (href) {
  //   return (
  //     <Link component={RouterLink} href={href} sx={styles}>
  //       {renderContent}
  //     </Link>
  //   );
  // } else if (href?.state) {
  //   return (
  //     <Link
  //       onClick={() =>
  //         navigate(href?.path, {
  //           state: href?.state,
  //         })
  //       }
  //       sx={styles}
  //     >
  //       {renderContent}
  //     </Link>
  //   );
  // } else if (href?.path) {
  //   return (
  //     <Link onClick={() => router.push(href?.path)} sx={styles}>
  //       {renderContent}
  //     </Link>
  //   );
  // }
  // =====================================================================================  1st
  // =====================================================================================  2nd
  if (typeof href === 'number') {
    return (
      <Link onClick={() => navigate(href)} sx={styles}>
        {renderContent}
      </Link>
    );
  } else if (href) {
    return (
      <Link component={RouterLink} href={href} sx={styles}>
        {renderContent}
      </Link>
    );
  }
  // =====================================================================================  2nd

  return <Box sx={styles}> {renderContent} </Box>;
}

BreadcrumbsLink.propTypes = {
  activeLast: PropTypes.bool,
  disabled: PropTypes.bool,
  link: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.node,
    name: PropTypes.string,
  }),
};
