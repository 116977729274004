import React from 'react';
import { Helmet } from 'react-helmet-async';
import AddRegistrationView from 'src/sections/HSSF/registration/add-registration';

const AddRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : ADD REGISTRATION</title>
    </Helmet>

    <AddRegistrationView />
  </>
);

export default AddRegistrationMain;
