import React from 'react';
import { Helmet } from 'react-helmet-async';

const CorporateMain = () => (
  <>
    <Helmet>
      <title> HSSF : CORPORATE</title>
    </Helmet>

    <h1>Corporate Main</h1>
  </>
);

export default CorporateMain;
